





























































	
	import { assuntoService, moduloService , moduloAdminService }  from '@/servicegerente';  
	import $ from "jquery"  
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
    const gerente = namespace('gerente') 
    import axios from 'axios';
    import FormModuloDisciplinasAssuntos from './_AssuntoFormAdicionar.vue'  

    @Component({
        components: {
            'formAddAssuntoToModulo':  FormModuloDisciplinasAssuntos,
        },
    })
    export default class AssuntoIndex extends Vue {

        @usuario.Getter
		public isAdmin!:  (  ) => boolean


        @gerente.State
		public modulo!: any


        @gerente.State
		public disciplina!: any 

        public assuntos: any= null; 


        @gerente.State
		public assunto!: any 


        @gerente.Action
        public alterarAssunto!: ( assunto: any  ) => void


        @gerente.Action
        public resetAssunto!: (   ) => void


        @gerente.Action
        public resetDisciplina!: (   ) => void

        public limparDisciplina( ) {  
            this.resetDisciplina();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }

        
		buscarAssunto() {
            if(this.disciplina){
                window.alertProcessando();
                moduloAdminService.buscarAssunto( this.modulo.id , this.disciplina.id )   
                .then(response => {
                    this.assuntos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro("Não foi possivel achar os Assuntos para adiocionar", error.data);
                    window.alertProcessandoHide();
                });   
            }
            else{ 
				this.$router.push('/v2/administracao/disciplina');  
            }
			
		}

        
		assuntoAdicionado(event: any) {
			this.assuntos = event;
			this.reloadDatatable = !this.reloadDatatable; 
		}


        public atualizaUrl( ) {  
           if(!this.disciplina){
				 this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/assuntos/datatable'
			}
			else{
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/assuntos/datatable'
			} 
        }

        public datatableKey = 0; 
		public  reloadDatatable = false ; 


        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else {
                 this.atualizaUrl( );
            } 
            this.buscarAssunto();
        }
 
		public config = { 
            order: [[ 10, "asc" ]],
			ajax: { 
				url:  assuntoService.getUrl() + '/datatable', 
			},
			columns: [
				{ data: 'id', name: 'assunto.id' , class: 'text-center' },
				{ data: 'nome', name: 'assunto.nome' },  
				{ data: 'descricao', name: 'assunto.descricao' },  
				{ data: 'disciplina.nome', name: 'disciplina.nome' },  
				{ data: 'tags_count', name: 'tags_count', class: 'text-center' }, 
				{ data: 'perguntas_restrita_count', name: 'perguntas_restrita_count', class: 'text-center' },
				{ data: 'perguntas_questionario_count', name: 'perguntas_questionario_count', class: 'text-center' },
				{ data: 'perguntas_chs_count', name: 'perguntas_chs_count', class: 'text-center' },
				{ data: 'cards_count', name: 'cards_count', class: 'text-center' },
				{ data: 'audios_count', name: 'audios_count', class: 'text-center' },
				{ data: 'sequencia', name: 'sequencia', class: 'text-center' },
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};
 
		public tableatualizada( ) {  
            
            
            $('[btn-select]').click(  (btn) => {   
                this.alterarAssunto($(btn.currentTarget).data( ));
                window.toastSucesso('assunto selecionada com sucesso!!' );  
            });

            
            $('[btn-visualizar-perguntas-duplicada]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/assunto/' + id + '/perguntas/duplicadas') ; 
            });
            
            $('[btn-localizar-perguntas]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/assunto/' + id + '/localizar/perguntas') ; 
            });
             
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                // this.$router.push('/assunto/show/' + id  );
                window.open('#/assunto/show/' + id ) ; 
            });

            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/assunto/edit/' + id  ) ;
                // this.$router.push('/assunto/edit/' + id  );
            });
 
            $('[btn-cadastrar-api]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma o Cadastro de respostas','' , 
                     () => {  
                        window.alertProcessando(); 
                        assuntoService.responderApi(  id  , {}  )
                        .then(response => {
                            window.alertProcessandoHide();    
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
            
            
			 $('[btn-excluir]').click(  (btn) => {  
                if( this.disciplina){
                    const id =   $(btn.currentTarget).data('id');  
                    window.alertConfimacao('Confirma a Exclusão ','' , 
                        () => {  
                            window.alertProcessando(); 
                            moduloAdminService.removerAssunto( this.modulo.id , this.disciplina.id ,  id )
                            .then(response => {
                                window.alertProcessandoHide();  
                                this.assuntos = response;
                                this.reloadDatatable = !this.reloadDatatable; 
                                window.toastSucesso('Assunto removido com sucesso!!' ); 
                            })
                            .catch(error => { 
                                this.reloadDatatable = !this.reloadDatatable; 
                                window.alertProcessandoHide();
                                window.toastErro('Não foi possivel remover a Assunto' , ''); 
                            });  
                        }
                    ); 
			    } 
            }); 
		}
		 
	}



 

