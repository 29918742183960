 
// const  url =  'http://chs80.local/api/v1' ; 
// const  urlSite =  'http://chs80.local' ; 
 

// const  url =  'http://127.0.0.1:8085/api/v1' ; 
// const  urlSite =  'http://127.0.0.1:8085' ; 
 
 
// const  url =  'https://teste.guiaonlinechs.com.br/api/v1' ; 
// const  urlSite =  'https://teste.guiaonlinechs.com.br' ; 


const  url =  'https://admin-api.guiaonlinecursos.com.br/api/v1' ; 
const  urlSite =  'https://admin-api.guiaonlinecursos.com.br' ; 


// const  url =  'https://aluno.guiaonlinecursos.com.br/api/v1' ; 
// const  urlSite =  'https://aluno.guiaonlinecursos.com.br' ; 



const  apiMap =  'AIzaSyCxGKoF5sKhGhQCxD3h-i0zdbd_mQwyvhc' ; 


 
function getApi(  ) { 
	return  url; 
}
 
function getSiteApi(  ) { 
	return  urlSite; 
}


function getApiMap(  ) { 
	return  apiMap; 
}



export const apiModel = { 
	getApi,  
	getApiMap, 
	getSiteApi, 
};
 